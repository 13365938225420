import React, { useEffect, useState } from 'react'
import ContentHero from '~/components/Content/Hero'
import ContentSection from '~/components/Content/Section'
import type { IPage } from 'graphql-cms/types'
import ScrollingBar from '../ScrollingBar'
import type { ISales } from 'basta-ai/queries/sales'
import { getAllSales } from '~/utils'
import Loading from '~/components/Loading'
import {Link, ThemeUICSSObject } from 'theme-ui'

interface Props {
  page: IPage
  removeMobilePadding?: boolean // Horizontal mobile padding
  sectionsContainerSx?: ThemeUICSSObject
}

const Ticker = ({ scrollingAnnouncements }) => {
  const activeAnnouncementsCollection = (
    scrollingAnnouncements[0]?.announcementsCollection?.items || []
  ).filter(
    (annBarCF: { endDate: string }) =>
      new Date(annBarCF.endDate).getTime() - new Date().getTime() > 0
  )
  const travelSpeed = scrollingAnnouncements[0]?.travelSpeed || '50'
  const defaultTicker = (
    <Link href='/collections' key={1}>
      <span>
        VIEW ______ THE DROP<span>VIEW ______ THE DROP</span>{' '}
      </span>
    </Link>
  )

  if (activeAnnouncementsCollection.length === 0) {
    return <ScrollingBar customText={[defaultTicker]} />
  }

  return (
    <ScrollingBar
      speed={parseInt(travelSpeed)}
      announcementsCollection={activeAnnouncementsCollection}
    />
  )
}

const ContentPage: React.FC<Props> = ({
  page,
  removeMobilePadding = false,
  sectionsContainerSx = {},
}) => {
  const [sales, setSales] = useState<ISales[] | null>(null)
  const { items: sections } = page.sectionsCollection
  const { items: scrollingAnnouncements } = page.scrollingBarCollection

  const init = () => {
    if (!sales) {
      getAllSales().then((sales) => setSales(sales))
    }
  }

  useEffect(() => {
    init()
  })

  if (!sales) return <Loading />
  return (
    <>
      {page.hero && (
        <ContentHero
          heading={page.hero.heading}
          subheading={page.hero.subheading}
          image={page.hero.image}
          imageMobile={page.hero.imageMobile}
        />
      )}

      {page.showScrollingBar && (
        <Ticker scrollingAnnouncements={scrollingAnnouncements} />
      )}

      {sections.map((section) => (
        <ContentSection
          key={section.sys.id}
          section={section}
          sales={sales}
          removeMobilePadding={removeMobilePadding}
          sectionsContainerSx={sectionsContainerSx}
        />
      ))}
    </>
  )
}

export default ContentPage
